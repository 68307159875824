<template>
  <v-container fill-height fluid>
    <v-card
      elevation="4"
      :width="this.$vuetify.breakpoint.xsAndDown ? '100%' : '644px'"
      class="mx-auto"
    >
      <v-card-title>{{ $t('content_settings') }}</v-card-title>

      <v-card-text>
        <v-list
          nav
        >
          <v-list-item-group color="secondary">
            <v-list-item
              v-for="item in availableMenuItems"
              :key="item.title"
              :to="getDestinationObj(item)"
              class="settings-list-item"
            >
              <v-list-item-icon>
                <v-icon class="pl-3" color="secondary" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<style scoped>
  .settings-list-item {
    background-color: var(--v-primary-base);
    margin-bottom: 15px !important;
    height: 50px;
    font-weight: 600;
  }

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--v-secondary-base) !important;
  }

  .settings-list-item > .v-list-item__icon > i {
    font-size: 1.75rem;
    height: 18px;
  }
</style>

<script>

import { mapState } from 'vuex'

export default {
  name: "ContentSettings",

  computed: {
    ...mapState({
      userRoles: state => state.core.auth.userRoles,
      menuItems: state => state.app.contentSettingsItems,
    }),

    availableMenuItems() {
      return this.menuItems.filter(
        i => i.access_role.some(
          r => this.userRoles.includes(r)
        )
      )
    }
  },

  methods:{
    getDestinationObj(item) {
      return {
        name: item.routeName,
        params: { appId: this.$route.params.appId }
      }
    }
  },
}

</script>
